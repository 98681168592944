import React from 'react';
import { Layout } from '../components/Layout';
import { PrivateRoute } from '../components/PrivateRoute';
import { Footer } from '../modules/Footer';
import { Menu } from '../modules/Menu';
import { Transactions } from '../modules/Transactions';
import { TransactionsType as Type } from '../modules/Transactions/utils/types';

const MyOrders = ({ location }: any) => (
  <div className="bg-wild-sand">
    <Layout>
      <Menu menuHasTwoColors={false} />
      <Transactions type={Type.ORDERS} locale={location.href} />
      <Footer />
    </Layout>
  </div>
);

const myOrdersPage = ({ ...props }) => (
  <PrivateRoute
    location={props.location.pathname}
    locale={props.location.href}
    component={MyOrders}
  />
);

export default myOrdersPage;
